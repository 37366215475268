import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorDisplay } from './error-display';
import { Icon } from './icon';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { Button } from './button';
import { Input } from './fields/input';
import { apiClient } from '@utils/api-client';
import { EMAIL_REGEX } from './modals/payment-modal/payment-form';
import { styled } from '../../stitches.config';

type Props = {
  data: {
    error: {
      errorDetails: {
        liveSessionId: string;
        documentId: string;
      };
      errorCode: string;
      errorReason: string;
      path: string;
      status: number;
    } | null;
  };
};

export const UploadError: React.FC<Props> = ({ data }) => {
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const { register, formState, handleSubmit } = useForm();

  return (
    <ErrorDisplay
      heading={'We are having trouble reading your document'}
      body={
        <>
          We are not quite sure what causes the issue, but we would love to
          solve it. <br />
          Please leave your email address, and we will be in touch to figure
          this out.
        </>
      }
      actionComponent={
        emailSubmitted ? (
          <div>
            <Icon icon={faCheck} color="#0060FF" /> Thanks! We’ll be in touch
            shortly.
          </div>
        ) : (
          <EmailForm
            onSubmit={handleSubmit(async (formData) => {
              const sessionId = data.error?.errorDetails.liveSessionId;
              const documentId = data.error?.errorDetails.documentId;
              const supportData = {
                userEmail: formData.email,
                code: data.error?.errorCode,
                liveSessionId: sessionId,
                documentId,
                reason: data.error?.errorReason,
                path: data.error?.path,
                status: data.error?.status,
              };
              try {
                setEmailSubmitted(true);
                await apiClient.post(
                  `/api/v1/live-session/document-read-error`,
                  supportData,
                );
              } catch {
                setEmailSubmitted(false);
              }
            })}
          >
            <EmailInput
              {...register('email', {
                required: 'Please enter your email',
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Invalid email address',
                },
              })}
              errors={formState.errors}
              label="Your email address"
            />
            <Button
              css={{ marginTop: '24px', marginLeft: '16px' }}
              variant="solid"
              color="primary"
            >
              Submit
            </Button>
          </EmailForm>
        )
      }
    />
  );
};

const EmailForm = styled('form', {
  display: 'flex',
  justifyContent: 'space-between',
});

const EmailInput = styled(Input, {
  flex: 1,
});
