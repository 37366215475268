import { styled } from '../../../stitches.config';

export const ModalTitle: React.FC = ({ children }) => {
  return <Title>{children}</Title>;
};

const Title = styled('h2', {
  color: '#0a235c',
  fontSize: '1.5rem',
  lineHeight: '2rem',
  marginBottom: '2rem',
  fontWeight: 'normal',
  textAlign: 'center',
});
