import { Headline, Text } from './styles';
import { faCheck as faCheckSolid } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { Spacer } from '@components/spacer';
import { Icon } from '@components/icon';
import { Spinner } from '@components/spinner';

export const DocumentIsProcessing: React.FC = () => {
  return (
    <>
      <Headline>
        <Icon icon={faCheckSolid} size="1x" color="#0060FF" />
        <Spacer size={8} inline />
        Payment confirmed
      </Headline>
      <Spinner size={82} />
      <Text css={{ fontWeight: 500 }} color="main">
        We&apos;re still generating your Word file.
      </Text>
      <Text>
        This is normal, generating your document might take a few minutes. Feel
        free to close this window, we&apos;ll email you the download link as
        soon as it&apos;s ready.
      </Text>
    </>
  );
};
