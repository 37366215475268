import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import { faAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp';
import { Icon } from '@components/icon';
import { Spinner } from '@components/spinner';
import { usePreviewMachine } from '@machines/preview.machine';
import { getTokens } from '@utils/token-mapper';
import { styled } from '../../stitches.config';

export const SuggestionsBrowser: React.FC = () => {
  const [state, send] = usePreviewMachine();

  const allSuggestionSentencePairs = state.context.sentences
    .filter((sentence) => !sentence.hideSuggestions)
    .flatMap((sentence) =>
      sentence.suggestions.map((suggestion) => ({
        sentence,
        suggestion,
        tokens: getTokens(
          sentence.text,
          suggestion.start,
          suggestion.end,
          suggestion.replacement,
        ),
      })),
    );

  const hasNoSuggestions = allSuggestionSentencePairs.length === 0;
  const fetchingSuggestions = state.context.sentences.some(
    (sentence) => !sentence.done,
  );

  if (fetchingSuggestions && hasNoSuggestions) {
    return (
      <SpinnerContainer>
        <Spinner size={64} />
      </SpinnerContainer>
    );
  }

  if (hasNoSuggestions) {
    // it's returning a div instead of null, because the flex styling needs a html element
    return <Container />;
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const selectedSuggestionPair =
    allSuggestionSentencePairs.find(
      ({ suggestion }) => suggestion.id === state.context.selectedSuggestionId,
    ) || allSuggestionSentencePairs[0];

  const { suggestion, tokens } = selectedSuggestionPair;

  return (
    <Container>
      <Header>
        <Counter>
          {allSuggestionSentencePairs.indexOf(selectedSuggestionPair) + 1} of{' '}
          {allSuggestionSentencePairs.length}
        </Counter>
        <SpinnerWrapper>{fetchingSuggestions && <Spinner />}</SpinnerWrapper>
        <Arrow
          onClick={() => {
            send('SELECT_PREVIOUS_SUGGESTION');
          }}
        >
          <Icon icon={faAngleUp} size="lg" />
        </Arrow>
        <Arrow
          onClick={() => {
            send('SELECT_NEXT_SUGGESTION');
          }}
        >
          <Icon icon={faAngleDown} size="lg" />
        </Arrow>
      </Header>
      <Suggestion>
        <Category>{suggestion?.category}</Category>
        <Explanation>{suggestion?.explanation}</Explanation>
        <Text>
          ... {tokens[0]}
          <Replacement>{tokens[1]}</Replacement>
          {tokens[2]} ...
        </Text>
      </Suggestion>
    </Container>
  );
};

const Container = styled('div', {
  position: 'sticky',
  top: '136px',
  zIndex: 2,
});

const SpinnerContainer = styled(Container, {
  margin: '32px 0 0 90px',
});

const Header = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '#0a235c',
});

const Counter = styled('div', {
  fontSize: '14px',
  fontWeight: 500,
  letterSpacing: 0,
  lineHeight: '32px',
  color: '#0a235c',
});

const SpinnerWrapper = styled('div', {
  marginLeft: '6px',
  flexGrow: 1,
});

const Arrow = styled('div', {
  cursor: 'pointer',
  background: '#fff',
  borderRadius: '0.4rem',
  height: '40px',
  fontSize: '16px',
  width: '40px',
  display: 'grid',
  placeContent: 'center',
  '&:hover': {
    background: '#eef3f6',
  },
  '&:active': {
    background: '#dde8ed',
  },
});

const Suggestion = styled('div', {
  border: '1px solid #afb7ca',
  borderRadius: '8px',
  backgroundColor: '#ffffff',
  marginTop: '8px',
  padding: '24px 1rem',
  boxShadow: '0px 8px 32px -16px rgba(10, 35, 92, 0.25)',
  color: '#0a235c',
});

const Category = styled('div', {
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '16px',
  marginBottom: '8px',
  '&::selection': {
    backgroundColor: 'transparent',
  },
});

const Explanation = styled('div', {
  fontSize: '14px',
  lineHeight: '16px',
  marginBottom: '24px',
});

const Text = styled('div', {
  fontSize: '$regular',
  textAlign: 'center',
  marginBottom: '16px',
  fontFamily: 'IBM Plex Serif',
});

const Replacement = styled('span', {
  fontWeight: 600,
  fontFamily: 'IBM Plex Serif',
});
