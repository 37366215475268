// Copyright (C) 2020 UNSILO. All Rights Reserved.

import { styled, keyframes } from '../../stitches.config';

type Props = {
  size?: number;
  speed?: number;
};

export const Spinner: React.FC<Props> = ({ size = 12, speed = 1 }) => (
  <StyledSpinner css={{ height: size, width: size }}>
    <Circle css={{ animationDuration: `${speed}s` }} />
  </StyledSpinner>
);

const StyledSpinner = styled('div', {
  boxSizing: 'border-box',
  position: 'relative',
  display: 'grid',
  placeItems: 'center',
});

const rotate = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
});

const Circle = styled('div', {
  position: 'absolute',
  place: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  animationName: `${rotate}`,
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',
  background: 'white',
  borderRadius: '50%',
  '&:before': {
    content: '',
    position: 'absolute',
    borderRadius: '50%',
    background:
      'linear-gradient(0deg,hsla(217, 100%, 50%, 0) 50%,hsla(217, 100%, 50%, 0.3) 100%) 0 0, linear-gradient(90deg, hsla(217, 100%, 50%, 0.3) 0%, hsla(217, 100%, 50%, 0.6) 100% ) 100% 0, linear-gradient( 180deg, hsla(217, 100%, 50%, 0.6) 0%, hsla(217, 100%, 50%, 0.9) 100% ) 100% 100%, linear-gradient(360deg, hsla(217, 100%, 50%, 0.9) 0%, hsla(217, 100%, 50%, 1) 100%) 0 100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '50% 50%',
    place: 0,
  },

  '&:after': {
    content: '',
    position: 'absolute',
    background: 'white',
    borderRadius: '50%',
    place: '2px',
  },
});
