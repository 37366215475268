type ApiMethod = <ResponseType = unknown>(
  url: RequestInfo,
  config?: RequestInit,
) => Promise<ResponseType>;

type ApiMethodWithBody = <ResponseType = unknown>(
  url: RequestInfo,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any,
  config?: RequestInit,
) => Promise<ResponseType>;

type ApiClient = {
  get: ApiMethod;
  post: ApiMethodWithBody;
  put: ApiMethodWithBody;
  patch: ApiMethodWithBody;
  delete: ApiMethod;
};

/**
 * A collection of methods to request API endpoints.
 * post, put and patch takes a second 'body' argument that is automatically stringified before requesting
 *
 * Example: apiClient.post('https://google.dk', { prop: true }, config)
 */
export const apiClient: ApiClient = {
  get: (url, config) => fetchWrapper(url, { method: 'GET', ...config }),

  post: (url, body, config) =>
    fetchWrapper(url, buildFetchWriteConfig('POST', body, config)),

  put: (url, body, config) =>
    fetchWrapper(url, buildFetchWriteConfig('PUT', body, config)),

  patch: (url, body, config) =>
    fetchWrapper(url, buildFetchWriteConfig('PATCH', body, config)),

  delete: (url, config) => fetchWrapper(url, { method: 'DELETE', ...config }),
};

/**
 * A lean abstraction on top of fetch, to improve error handling, mocking and other small things.
 * Should never be used directly, instead use apiClient.METHOD
 * Inspired by
 * - https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper
 * - https://jasonwatmore.com/post/2020/04/18/fetch-a-lightweight-fetch-wrapper-to-simplify-http-requests
 */
const fetchWrapper = async <ResponseType = unknown>(
  url: RequestInfo,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: RequestInit & { body?: any },
): Promise<ResponseType> => {
  const actualConfig: RequestInit = {
    ...config,
  };
  if (config.body) {
    actualConfig.body = JSON.stringify(config.body);
  }

  // In the browser we need to wait for our mocks to be ready before making a request. https://github.com/mswjs/msw/issues/73
  // This is not an issue in production, since we'd just be awaiting an undefined
  if (
    typeof window !== 'undefined' &&
    process.env.NEXT_PUBLIC_ENABLE_MOCKS === 'true'
  ) {
    await window.__mocksReady;
  }

  const response = await fetch(url, actualConfig);

  if (!response.ok) {
    const error = await ApiError.build(url, actualConfig, response);
    error.logToConsole();
    throw error;
  }

  // try to parse response as JSON. If it fails, return the raw response string
  const text = await response.text();
  try {
    const json = JSON.parse(text);
    return json;
  } catch {
    return text as unknown as ResponseType;
  }
};

/**
 * function that abstracts the common configuration building of POST, PUT and PATCH.
 */
const buildFetchWriteConfig = (
  method: 'POST' | 'PUT' | 'PATCH',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any,
  config?: RequestInit,
) => ({
  method,
  body,
  ...config,
  headers: {
    'Content-Type': 'application/json',
    ...(config && config.headers),
  },
});

/**
 * Error that contains all information related to a fetch request and response
 * use (the async) ApiError.build to build a new instance
 */
export class ApiError extends Error {
  public url!: RequestInfo;
  public requestConfig!: RequestInit;
  public response!: Record<string, unknown>;
  public rawResponse!: Response;
  public text!: string;
  public json: unknown;

  private constructor(message: string) {
    super(message);
    this.name = 'ApiError';
  }

  public static async build(
    url: RequestInfo,
    requestConfig: RequestInit,
    rawResponse: Response,
  ): Promise<ApiError> {
    const text = await rawResponse.text();
    const message = `${rawResponse.status} ${rawResponse.statusText}: ${text}`;
    const error = new ApiError(message);
    error.url = url;
    error.requestConfig = requestConfig;
    error.rawResponse = rawResponse;
    // converts the response into a plain object so it can be logged in the console and added to the DOM
    error.response = {
      headers: Object.fromEntries(rawResponse.headers),
      ok: rawResponse.ok,
      redirected: rawResponse.redirected,
      status: rawResponse.status,
      statusText: rawResponse.statusText,
      trailer: rawResponse.trailer,
      type: rawResponse.type,
      url: rawResponse.url,
    };
    error.text = text;
    try {
      error.json = JSON.parse(text);
    } catch {}

    return error;
  }

  public logToConsole() {
    const { rawResponse, ...object } = this;
    // eslint-disable-next-line no-console
    console.error(this.name, object);
  }
}
