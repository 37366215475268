import { styled } from '../../../stitches.config';

export const CoreHelperText: React.FC = ({ children, ...props }) => {
  return (
    <StyledHelperText {...props}>{children || <>&nbsp;</>}</StyledHelperText>
  );
};

const StyledHelperText = styled('p', {
  marginBottom: '0 !important',
  fontWeight: 400,
});
