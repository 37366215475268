import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type {
  Stripe,
  StripeCardElement,
  StripeError,
  StripeCardElementChangeEvent,
} from '@stripe/stripe-js';
import { useEffect } from 'react';
import {
  DeepMap,
  FieldError,
  SubmitHandler,
  useForm,
  Controller,
} from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { isDevelopment } from '@utils/helpers';
import { Button } from '@components/button';
import { track, TrackingType } from '@tracking/ga';
import { useModal } from '@hooks/use-modal';
import { COUNTRIES } from '@utils/countries';
import { TocModal } from '@components/modals/toc-modal/toc-modal';
import { Checkbox } from '@components/fields/checkbox';
import { Input } from '@components/fields/input';
import { Select } from '@components/fields/select';
import { Spinner } from '@components/spinner';
import { PRICE } from '@utils/constants';
import { Pipe } from '@components/pipe';
import { styled } from '../../../../stitches.config';

export const EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex
  /(?:[\d!#$%&'*+/=?^_`a-z{|}~-]+(?:\.[\d!#$%&'*+/=?^_`a-z{|}~-]+)*|"(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F!\u0023-\u005B\u005D-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])*")@(?:(?:[\da-z](?:[\da-z-]*[\da-z])?\.)+[\da-z](?:[\da-z-]*[\da-z])?|\[(?:(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d{1,2}|[\da-z-]*[\da-z]:(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F\u0021-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])+)])/;

export type PaymentInputValues = {
  name: string;
  zipCode: string;
  city: string;
  country: string;
  terms: boolean;
  email: string;
  creditCard: string;
};

type Props = {
  onSubmit: (args: {
    values: PaymentInputValues;
    cardElement: StripeCardElement;
    stripe: Stripe | null;
  }) => void;
  isCapturingPayment: boolean;
  paymentCaptureError?: StripeError;
};

const getCountries = () =>
  Object.entries(COUNTRIES).map(([value, label]) => ({ value, label }));

export const PaymentForm: React.FC<Props> = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const { openModal, Modal } = useModal();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setError,
    clearErrors,
  } = useForm<PaymentInputValues>(
    isDevelopment
      ? {
          defaultValues: {
            // name: 'John doe',
            zipCode: '0000',
            city: 'John town',
            // country: 'DK',
            terms: true,
            email: 'john.doe@cactusglobal.com',
          },
        }
      : {},
  );

  useEffect(() => {
    track(
      'event',
      TrackingType.Category.paymentPage,
      TrackingType.Actions.paymentIntent,
    );
  }, []);

  // read errors from payment capturing and attach them to the credit card field
  useEffect(() => {
    if (!props.paymentCaptureError) {
      clearErrors('creditCard');
      return;
    }
    setError('creditCard', {
      type: 'capture',
      message: props.paymentCaptureError.message,
    });
  }, [props.paymentCaptureError, setError, clearErrors]);

  const onSubmit: SubmitHandler<PaymentInputValues> = async (values) => {
    const cardElement = elements?.getElement(CardElement);
    if (!cardElement) {
      throw new Error('no stripe card element');
    }
    props.onSubmit({ values, cardElement, stripe });
  };

  const onError = (errors: DeepMap<PaymentInputValues, FieldError>) => {
    (Object.keys(errors) as Array<keyof typeof errors>).forEach((key) => {
      const error = errors[key];
      track(
        'event',
        TrackingType.Category.paymentPage,
        TrackingType.Actions.error,
        `<${error?.message}>`,
      );
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <NameInput
          {...register('name', { required: 'Please enter your name' })}
          errors={errors}
          label="Your name"
        />
        <ZipCodeInput
          {...register('zipCode', { required: 'Please enter your zip' })}
          errors={errors}
          label="Zip code"
        />
        <CityInput
          {...register('city', { required: 'Please enter your city' })}
          errors={errors}
          label="City"
        />
        <CountrySelect
          {...register('country', { required: 'Please enter your country' })}
          errors={errors}
          options={getCountries()}
          label="Country"
        />
        <EmailInput
          {...register('email', {
            required: 'Please enter your email',
            pattern: {
              value: EMAIL_REGEX,
              message: 'Invalid email address',
            },
          })}
          errors={errors}
          label="Your email address"
        />
        <Controller
          control={control}
          name="creditCard"
          rules={{
            validate: ((event: StripeCardElementChangeEvent | undefined) => {
              // show Stripe's internal error message if it has one
              if (event?.error?.message) {
                return event.error.message;
              }

              // show required text but only if validating during a submission
              if (isSubmitting && !event?.complete) {
                return 'Please enter your credit card details';
              }
              // credit card is complete during submission
              return true;
            }) as any,
          }}
          render={({ field: { onChange, onBlur } }) => (
            <CCInput
              label="Credit card"
              name="creditCard"
              errors={errors}
              Component={
                <CardElement
                  options={{
                    hidePostalCode: true,
                    style: {
                      base: {
                        fontSize: '16px',
                        lineHeight: '1.5rem',
                      },

                      invalid: {
                        color: '#cc0044',
                      },
                    },
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              }
            />
          )}
        />
        <TermsCheckbox
          {...register('terms', {
            required: 'Please read and accept the terms of condition',
          })}
          errors={errors}
          label={
            <>
              I accept the{' '}
              <LinkButton type="button" onClick={openModal}>
                terms and conditions
              </LinkButton>
            </>
          }
        />
        <CtaButton
          onClick={() =>
            track(
              'event',
              TrackingType.Category.paymentPage,
              TrackingType.Actions.click,
              TrackingType.Labels.paymentIntentFinal,
            )
          }
          variant="solid"
          color="primary"
          disabled={props.isCapturingPayment}
        >
          {props.isCapturingPayment ? (
            <Spinner size={24} />
          ) : (
            <>
              Pay and download now
              <Pipe />
              {PRICE}
            </>
          )}
        </CtaButton>
      </Form>
      <Modal>
        <TocModal />
      </Modal>
      {isDevelopment && <DevTool control={control} placement="top-left" />}
    </>
  );
};

const Form = styled('form', {
  display: 'grid',
  height: '100%',
  width: '100%',
  columnGap: '1rem',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gridTemplateRows: 'repeat(5, auto)',
  alignItems: 'center',
});

const LinkButton = styled('button', {
  border: 'none',
  background: 'none',
  fontSize: 'inherit',
  color: '$mainPrimary',
  cursor: 'pointer',
  textDecoration: 'underline',
  padding: '0',
  textAlign: 'end',
});

const CtaButton = styled(Button, {
  gridColumn: '1/-1',
});

const NameInput = styled(Input, {
  gridColumn: '1/-1',
});
const ZipCodeInput = styled(Input, {
  gridColumn: '1/3',
});
const CityInput = styled(Input, {
  gridColumn: '3/-1',
});
const CountrySelect = styled(Select, {
  gridColumn: '1/-1',
});
const TermsCheckbox = styled(Checkbox, {
  gridColumn: '1/-1',
});
const EmailInput = styled(Input, {
  gridColumn: '1/-1',
});

const CCInput = styled(Input, {
  gridColumn: '1/-1',
});
